import React, { FC } from 'react';
import StudentLogin from '../components/Login/Login';

const LoginPage: FC = () => {
  return (
    <div>
      <StudentLogin />
    </div>
  );
};

export default LoginPage;
