import React, { FC } from 'react';
import Login from '../components/Login/HospitalLogin';

const HospitalLogin: FC = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default HospitalLogin;
