import React, { FC } from 'react';
import Resgister from '../components/Login/Register';

const RegisterPage: FC = () => {
  return (
    <div>
      <Resgister />
    </div>
  );
};

export default RegisterPage;
